import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./index.scss";
import socket from "../../helper/socket";
import { useDispatch, useSelector } from "react-redux";
import { getUploadedMediaUrl } from "../../helper/media";
import React, { useEffect, useState, useRef } from "react";
import { reqSetIsTransparent } from "../../reduxs/home/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import Image from "../image";

const FloorPlanGallery = (props) => {
  const { isPresentation } = props;
  const dispatch = useDispatch();
  const transformComponentRef = useRef(null);
  const [media, setMedia] = useState([]);
  const [mediaIndex, setMediaIndex] = useState(0);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isTransparent = useSelector((state) => state.home.isTransparent);

  useEffect(async () => {
    if (selectedUnit) {
      const floorPlan = selectedUnit?.floorPlan;
      setMedia(floorPlan?.media ? floorPlan.media : []);
      setMediaIndex(0);
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLOSE_FLOORPLAN_UNIT_DETAIL) {
          hideFloorplan();
        }
        if (
          content.action === ACTION_NAME.REOPEN_FILTER_FLOORPLAN_UNIT_DETAIL
        ) {
          showFilter();
        }
        if (content.action === ACTION_NAME.ZOOM_IN_FLOORPLAN) {
          if (!transformComponentRef.current) return;
          if (content.data.state) {
            return transformComponentRef.current.setTransform(
              content.data.state.positionX,
              content.data.state.positionY,
              content.data.state.scale
            );
          }
        }
        if (content.action === ACTION_NAME.ZOOM_OUT_FLOORPLAN) {
          if (!transformComponentRef.current) return;
          if (content.data.state) {
            const ratioHeight = window.screen.height / content.data.height;
            const ratioWidth = (window.screen.width * 0.7) / content.data.width;
            const marginWidth = ratioHeight > 1 ? 0.98 : 1.03;
            const marginHeight = ratioHeight > 1 ? 1.3 : 0.98;
            return transformComponentRef.current.setTransform(
              content.data.state.positionX * ratioWidth * marginWidth,
              content.data.state.positionY * ratioHeight * marginHeight,
              content.data.state.scale === 1
                ? 1
                : content.data.state.scale * (1 / marginWidth)
            );
          }
        }
        if (content.action === ACTION_NAME.NEXT_FLOORPLAN) {
          onNextFloorplan(content.data.index);
        }
        if (content.action === ACTION_NAME.PREV_FLOORPLAN) {
          onPrevFloorplan(content.data.index);
        }
      });
    }
  }, [isPresentation]);

  const showFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.REOPEN_FILTER_FLOORPLAN_UNIT_DETAIL);
    }
    dispatch(reqSetIsTransparent(false));
  };

  const hideFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_FLOORPLAN_UNIT_DETAIL);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetIsTransparent(false));
  };

  const onNextFloorplan = (index) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.NEXT_FLOORPLAN, { index });
    }
    setMediaIndex(index + 1);
  };

  const onPrevFloorplan = (index) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PREV_FLOORPLAN, { index });
    }
    setMediaIndex(index - 1);
  };

  return (
    <TransformWrapper
      ref={transformComponentRef}
      maxScale={2.5}
      minPositionX={window.screen.width * 1.55 * -1}
      minPositionY={window.screen.height * 1.7 * -1}
      maxPositionX={window.screen.width * 1.55}
      maxPositionY={window.screen.height * 1.7}
      wheel={{
        step: 0.1,
      }}
      pinch={{
        step: 0.1,
      }}
      onTransformed={(ref, state) => {
        if (!isPresentation) {
          socket.emitUIActionEvent(ACTION_NAME.ZOOM_OUT_FLOORPLAN, {
            state,
            height: window.screen.height,
            width: window.screen.width,
          });
        }
      }}
    >
      {({ zoomIn, zoomOut }) => (
        <>
          <div
            className={`wrap-floor-plan-gallery ${
              !isPresentation ? "padding-top-bottom-nav" : ""
            }`}
          >
            <TransformComponent
              contentStyle={{ height: "100%", width: "100vw" }}
            >
              {media?.[mediaIndex] ? (
                <Image
                  src={getUploadedMediaUrl(media?.[mediaIndex].path)}
                  alt="floorplan"
                  imgClassName="floorplan-item"
                />
              ) : (
                <div className="floorplan-item img-not-found">
                  Image Not Found
                </div>
              )}
            </TransformComponent>
            <span
              className={`btn-gallery prev-slide ${
                !isTransparent ? "with-panels" : ""
              }`}
              hidden={!mediaIndex}
            >
              <img
                width="30"
                className="img-fluid"
                src="/icons/arrow-black.svg"
                alt=""
                onClick={() => onPrevFloorplan(mediaIndex)}
              />
            </span>
            <span
              className={`btn-gallery next-slide ${
                !isTransparent ? "with-panels" : ""
              }`}
              hidden={mediaIndex >= media.length - 1}
            >
              <img
                width="30"
                className="img-fluid"
                src="/icons/arrow-black.svg"
                alt=""
                onClick={() => onNextFloorplan(mediaIndex)}
              />
            </span>
          </div>
          {isTransparent && (
            <div className="wrap-close-btn">
              <div onClick={hideFloorplan}>
                <img
                  src="./images/icons/arrow-left.svg"
                  alt=""
                  className="rotate"
                />
                <span>Close floorplan</span>
              </div>
              <div onClick={showFilter}>
                <img src="/icons/add.svg" alt="" />
                <span>Reopen panels</span>
              </div>
            </div>
          )}
        </>
      )}
    </TransformWrapper>
  );
};

export default FloorPlanGallery;
