import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  reqGetListGalleriesCMS,
  reqSetEditableGallery,
  reqSetIsShowCreateGalleryModal
} from "../../reduxs/cms/action";
import GalleryCmsListItem from "../../components/gallery-cms/list-item";
import GalleryCMSCreateOverplay from "../../components/gallery-cms/overplay/create";
import LoadingIcon from "../../components/loading-icon";
import unitAPI from '../../apis/api/unit'
import './index.scss'

const ContentGallery = () => {
  const dispatch = useDispatch();
  const galleries = useSelector((state) => state.cms.galleriesCMS);
  const [unitList, setUnitList] = useState([])
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState('');
  const [show, setIsShow] = useState(false)
  const [acceptData, setAcceptData] = useState(['media_images', 'image']);
  const uniqueOrder = useMemo(() => {
    return (galleries || [] ).filter((item)=> acceptData.includes(item?.type)).map(it => (it.media?.[0]?.order ?? it.media?.[1]?.order ?? '')).filter(it => it).sort((a, b) => a - b);
  }, [galleries, acceptData]);

  useEffect(() => {
    getListGalleries();
  }, [search, isSortAsc])

  useEffect(() => {
    getUnitList()
  }, [])

  const getUnitList = async () => {
    const data = await unitAPI.getUnitList()
    if (data?.data) {
      setUnitList(data.data)
    }
  } 

  const getListGalleries = () => {
    dispatch(reqGetListGalleriesCMS({
      search,
      sortBy: JSON.stringify({
        name: isSortAsc ? 1 : -1,
      }),
      pageSize: 9999,
    }));
  }

  const onCreateGallery = () => {
    dispatch(reqSetIsShowCreateGalleryModal(true));
  }

  const onEditGallery = (id) => {
    dispatch(reqSetEditableGallery(id))
  }

  const renderListGalleries = (acceptData) => {

    if (!galleries?.length) {
      return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <LoadingIcon />
        </div>
      )
    }
    return (galleries || [] ).filter((item)=> acceptData.includes(item?.type)).sort((a, b) => (a.media?.[0]?.order ?? a.media?.[1]?.order ?? 999) - (b.media?.[0]?.order ?? b.media?.[1]?.order ?? 999)).map((item, index) => {
      return (
        <div key={item.id} className="col-4 mb-4">
          <GalleryCmsListItem uniqueOrder={uniqueOrder} unitList={unitList} item={item} getListGalleries={getListGalleries} />
        </div>
      )
    })
  }

  return (
    <>
      <div className="wrap-content-image page-container">
        <div className="page-header d-flex align-items-center gallery-cms">
          <h1 className="f-title mb-0">Gallery CMS</h1>
          <div className={'flex items-center justify-center group-btn'}>
            <button className="navigator-button" onClick={() => setAcceptData(['media_images', 'image'])}>Images</button>
            <button className="navigator-button" onClick={() => setAcceptData(["brickell_film"])}>Films</button>
            <div className="flex items-center justify-center btn-add-new-gallery cursor-pointer" onClick={()=>setIsShow(true)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0078 22.5C9.22304 22.5 6.55232 21.3938 4.58319 19.4246C2.61406 17.4555 1.50781 14.7848 1.50781 12C1.50781 9.21523 2.61406 6.54451 4.58319 4.57538C6.55232 2.60625 9.22304 1.5 12.0078 1.5C14.7926 1.5 17.4633 2.60625 19.4324 4.57538C21.4016 6.54451 22.5078 9.21523 22.5078 12C22.5078 14.7848 21.4016 17.4555 19.4324 19.4246C17.4633 21.3938 14.7926 22.5 12.0078 22.5ZM12.0078 24C15.1904 24 18.2427 22.7357 20.4931 20.4853C22.7435 18.2348 24.0078 15.1826 24.0078 12C24.0078 8.8174 22.7435 5.76516 20.4931 3.51472C18.2427 1.26428 15.1904 0 12.0078 0C8.82521 0 5.77297 1.26428 3.52253 3.51472C1.27209 5.76516 0.0078125 8.8174 0.0078125 12C0.0078125 15.1826 1.27209 18.2348 3.52253 20.4853C5.77297 22.7357 8.82521 24 12.0078 24Z" fill="#B2B2B2"/>
                <path d="M12.0078 6C12.2067 6 12.3975 6.07902 12.5381 6.21967C12.6788 6.36032 12.7578 6.55109 12.7578 6.75V11.25H17.2578C17.4567 11.25 17.6475 11.329 17.7881 11.4697C17.9288 11.6103 18.0078 11.8011 18.0078 12C18.0078 12.1989 17.9288 12.3897 17.7881 12.5303C17.6475 12.671 17.4567 12.75 17.2578 12.75H12.7578V17.25C12.7578 17.4489 12.6788 17.6397 12.5381 17.7803C12.3975 17.921 12.2067 18 12.0078 18C11.8089 18 11.6181 17.921 11.4775 17.7803C11.3368 17.6397 11.2578 17.4489 11.2578 17.25V12.75H6.75781C6.5589 12.75 6.36813 12.671 6.22748 12.5303C6.08683 12.3897 6.00781 12.1989 6.00781 12C6.00781 11.8011 6.08683 11.6103 6.22748 11.4697C6.36813 11.329 6.5589 11.25 6.75781 11.25H11.2578V6.75C11.2578 6.55109 11.3368 6.36032 11.4775 6.21967C11.6181 6.07902 11.8089 6 12.0078 6Z" fill="#B2B2B2"/>
              </svg>
              <div><span style={{fontFamily: "Suisse Intl", fontSize: '12px'}}>{acceptData.includes('brickell_film') ? 'ADD NEW FILM' : 'ADD NEW IMAGE'}</span></div>
            </div>
            <div className="input-group ms-auto page-header__input-search flex">
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.57924 18.1429C14.3131 18.1429 18.1507 14.3053 18.1507 9.57143C18.1507 4.83756 14.3131 1 9.57924 1C4.84537 1 1.00781 4.83756 1.00781 9.57143C1.00781 14.3053 4.84537 18.1429 9.57924 18.1429Z" stroke="#B2B2B2" strokeWidth="1.19048" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M25.1284 25.0003L15.5781 15.5718" stroke="#B2B2B2" strokeWidth="1.19048" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <input
                value={search}
                className="form-control search-input"
                type="text"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          </div>

        <div className="page-body">
          <div className="row wrap-media-list ">
            {renderListGalleries(acceptData)}
          </div>
        </div>
      </div>
      <GalleryCMSCreateOverplay uniqueOrder={uniqueOrder} isImage={acceptData.includes('media_images')} unitList={unitList} show={show} handleClose={(isReset)=>{
        if(isReset) {
          getListGalleries()
        }
        setIsShow(false)
      }} />
    </>
  )
}

export default ContentGallery;
